import { createSelector } from '@reduxjs/toolkit'
import { OutletState, ServiceTimeKindType } from '@ancon/wildcat-types'
import isOutletAcceptingPreOrders from '@ancon/wildcat-utils/outlet/isOutletAcceptingPreOrders'
import getOutletOrderFormats from '@ancon/wildcat-utils/outlet/getOutletOrderFormats'

import { RootState } from '../../../store/types'
import { OutletReducerState } from '../types'

function outletSelector<K extends keyof OutletReducerState>(
  state: RootState,
  key: K,
) {
  return state.outlet[key]
}

export const outletListItemSelector = (state: RootState) =>
  outletSelector(state, 'outletListItem')

export const outletDetailsSelector = (state: RootState) =>
  outletSelector(state, 'outletDetails')

export const outletSelectedOutletAddressSelector = (state: RootState) =>
  outletListItemSelector(state)?.address

export const outletDetailsIdASelector = (state: RootState) =>
  outletDetailsSelector(state)?.id

export const outletDetailsDeliveryAreaSelector = (state: RootState) =>
  outletDetailsSelector(state)?.deliveryArea

export const outletIsOutletDetailsPendingSelector = (state: RootState) =>
  outletSelector(state, 'isOutletDetailsPending')

export const outletSelectedOutletOrderFormatsSelector = (state: RootState) =>
  outletListItemSelector(state)?.supportedOrderFormats

export const outletSelectedOutletTenantIdSelector = (state: RootState) =>
  outletDetailsSelector(state)?.tenantId

export const outletMinimumPrepaidWalletTopUpAmountSelector = (
  state: RootState,
) => outletDetailsSelector(state)?.minimumWalletTopUpValue

export const outletOrderConfigFilterModalSelector = (state: RootState) =>
  state.outlet.orderConfigureFilterModal

export const outletIsVisibleDeliveryLocationModalSelector = (
  state: RootState,
) => outletSelector(state, 'isVisibleDeliveryLocationModal')

export const outletIsVisibleTableOrderConfigureModalSelector = (
  state: RootState,
) => outletSelector(state, 'isVisibleTableOrderConfigureModal')

export const outletIsVisibleBoxPickupOrderConfigureModalSelector = (
  state: RootState,
) => outletSelector(state, 'isVisibleBoxPickupOrderConfigureModal')

export const outletIsVisibleOutletDetailsModalSelector = (state: RootState) =>
  outletSelector(state, 'isVisibleOutletDetailsModal')

export const outletIsVisibleServiceTimeKindSelectionModalSelector = (
  state: RootState,
) => outletSelector(state, 'isVisibleServiceTimeKindSelectionModal')

export const outletFiltersSelector = (state: RootState) =>
  outletSelector(state, 'outletFilters')

export const outletFiltersDeliverySelector = (state: RootState) =>
  outletFiltersSelector(state)?.delivery

export const outletSelectedOutletIdSelector = (state: RootState) =>
  outletListItemSelector(state)?.id

export const outletSelectedOutletNameSelector = (state: RootState) =>
  outletListItemSelector(state)?.name

export const outletIsVisibleOutletChangeWarningModalSelector = (
  state: RootState,
) => outletSelector(state, 'isVisibleOutletChangeWarningModal')

export const outletTableNumberValidatePendingSelector = (state: RootState) =>
  outletSelector(state, 'outletTableNumberValidatePending')

export const outletTableNumberValidateErrorSelector = (state: RootState) =>
  outletSelector(state, 'outletTableNumberValidateError')

export const outletForceRefreshMenuSelector = (state: RootState) =>
  outletSelector(state, 'outletMenuForceRefresh')

export const outletFiltersOrderFormatSelector = (state: RootState) =>
  outletFiltersSelector(state).orderFormat

export const outletFiltersServiceTimeSelector = (state: RootState) =>
  outletFiltersSelector(state).serviceTime

export const outletFiltersSectionSelector = (state: RootState) =>
  outletFiltersSelector(state).section

export const outletFiltersServiceTimeKindTypeSelector = createSelector(
  [outletFiltersServiceTimeSelector],
  serviceTime =>
    serviceTime ? serviceTime.kind : ServiceTimeKindType.AsSoonAsPossible,
)

export const outletShowProductsFromOtherOrderFormatsSelector = createSelector(
  outletListItemSelector,
  outlet => !!outlet?.showAvailableProductsFromOtherOrderFormats,
)

export const outletIsOutletAcceptingPreOrdersSelector = createSelector(
  outletListItemSelector,
  outletListItem => {
    if (outletListItem) {
      const { state, orderFormatSettings } = outletListItem
      const isClosed = state === OutletState.Closed
      const isAcceptingPreOrders =
        isOutletAcceptingPreOrders(orderFormatSettings)

      return isClosed && isAcceptingPreOrders
    }
    return false
  },
)

export const outletListItemAvailableOrderFormatsSelector = createSelector(
  outletListItemSelector,
  outlet => (outlet ? getOutletOrderFormats(outlet) : []),
)
